.spinner {
  margin: 20px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #2e70e8;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.templatedetails-container {
  height: calc(100vh - theme('spacing.40'));
}

@media (min-width: 1536px) {
  .templatedetails-container {
    height: calc(100vh - theme('spacing.32'));
  }
}

.emaileditor-container {
  height: calc(100vh - theme('spacing.60'));
}

/* @media (min-width: 1536px) {
  .emaileditor-container {
    height: calc(100vh - theme('spacing.4'));
  }
} */

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

#dialog-container {
  /** border: dashed 2px pink; **/
  width: 360px;
  min-height: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

.dialog-box {
  position: relative;
  min-height: 80px;
  max-height: 180px;
  border-radius: 10px;
  margin-bottom: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
}

.dialog-box .header {
  height: 25px;
  position: relative;
  color: black;
}

.background-blur {
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
}

.left {
  float: left;
  text-align: left;
  width: content-max;
  padding: 3px 0 0 10px;
}

.right {
  float: right;
  text-align: right;
  width: content-max;
  padding: 3px 10px 0 0;
}

.contents {
  z-index: 100;
  position: inherit;
  font-size: 0.9em;
}

.main-content {
  padding: 8px 20px 10px 10px;
  height: content-max;
}

#footer-text {
  font-size: 0.8em;
  position: absolute;
  bottom: 8px;
  right: 8px;
}

/* (A) LIST TO MENU */
.tree,
.section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.tree {
  background: #fbfbfb;
  border: 1px solid #d2d2d2;
  @apply rounded-md;
}
.tree li {
  border-bottom: 1px solid #d2d2d2;
  padding: 15px 10px;
  @apply text-gray-700;
}
.tree li:last-child {
  border: 0;
}

/* (B) SUB-SECTIONS */
/* (B1) TOGGLE SHOW/HIDE */
.section ul {
  display: none;
}
.section input:checked ~ ul {
  display: block;
}

/* (B2) HIDE CHECKBOX */
.section input[type='checkbox'] {
  display: none;
}

/* (B3) ADD EXPAND/COLLAPSE ICON  */
.section {
  position: relative;
  padding-left: 35px !important;
}
.section label:after {
  content: '\0002B';
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  text-align: center;
  font-size: 25px;
  @apply text-gray-700;
  transition: all 0.5s;
}
.section input:checked ~ label:after {
  transform: rotate(45deg);
}

/* (B4) SUB-SECTION ITEMS */
.section ul {
  margin-top: 10px;
}

.speechbubble:before {
  content: '';
  width: 0px;
  height: 0px;
  position: relative;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid #fff;
  border-bottom: 10px solid transparent;
  left: -16px;
  top: 10px;
  @apply rounded;
}

.ag-theme-alpine .ag-root-wrapper {
  border: solid 0px !important;
  border-color: var(--ag-border-color, #fff) !important;
}

.collapse {
  width: 0.0000000001%;
  white-space: nowrap;
}

/* Custom properties */
:root {
  --tooltip-text-color: white;
  --tooltip-background-color: black;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}

/* Wrapping */
.Tooltip-Wrapper {
  display: inline-block;
  position: relative;
}

/* Absolute positioning */
.Tooltip-Tip {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
}

/* CSS border triangles */
.Tooltip-Tip::before {
  content: ' ';
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
.Tooltip-Tip.top {
  top: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.Tooltip-Tip.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.right {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.right::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
  bottom: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.Tooltip-Tip.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.left::before {
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: var(--tooltip-background-color);
}

.spinner2 {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 100px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

.animate-flicker {
  animation: fadeIn 2s infinite alternate;
}

#email-html-editor {
  width: '100%' !important;
  height: '100%' !important;
}

.readmeresults em {
  background-color: mark;
  color: marktext;
  font-style: normal;
}

/* START: phone input styles */

.PhoneInputInput {
  border-radius: 5px;
  border-color: hsl(var(--input)) !important;
  background-color: hsl(var(--background)) !important;
  height: 38px;
  font-size: 0.875rem !important;
  color: hsl(var(--foreground)) !important;
}

.PhoneInputInput:focus {
  box-shadow: 0 0 0 1px hsl(var(--ring)) !important;
  box-shadow: none;
}

/* END: phone input styles*/

button[title='Toggle'] {
  display: none;
}

.rct-node {
  list-style: none;
  margin-left: 30px;
}

.rct-title {
  padding-left: 10px;
  font-size: 14px;
}

.rct-checkbox {
  border-radius: 2px;
}

.react-checkbox-tree {
  margin-left: -30px;
}

.beamer_beamer.right {
  padding: 0px;
}

/* START: time picker in datetime component */

.rc-time-picker-input {
  color: hsl(var(--foreground));
  border-color: hsl(var(--input));
  background-color: transparent;
  font-size: 14px;
  height: 36px;
  border-radius: 6px;
}

.rc-time-picker-input:focus {
  outline: none;
}

.rc-time-picker-panel-inner {
  background-color: hsl(var(--background));
  width: max-content;
  margin-top: 3px;
  box-shadow: none;
  border-color: hsl(var(--input));
}

li.rc-time-picker-panel-select-option-selected {
  background-color: hsl(var(--primary-foreground));
}

.rc-time-picker-panel-select {
  border-color: hsl(var(--input));
}

.rc-time-picker-panel-select li:hover {
  background-color: hsl(var(--primary-foreground));
}

.rc-time-picker-panel-input {
  background-color: transparent;
  font-size: 14px;
}

.rc-time-picker-panel-input-wrap {
  padding: 2px 6px;
  border-bottom-color: hsl(var(--input));
}

.rc-time-picker-clear {
  display: none;
}

.rc-time-picker-input[disabled] {
  background-color: hsl(var(--primary-foreground));
}

/* END: time picker in datetime component */

.json-string {
  color: hsla(224, 55%, 21%, 1);
}
.json-number {
  color: hsla(224, 55%, 21%, 1);
}
.json-boolean {
  color: hsla(224, 55%, 21%, 1);
}

.json-null {
  color: hsla(224, 55%, 21%, 1);
}

.json-key {
  color: #d52071;
}

html.dark .json-string {
  color: hsla(210, 74%, 81%, 1);
}
html.dark .json-number {
  color: hsla(210, 74%, 81%, 1);
}
html.dark .json-boolean {
  color: hsla(210, 74%, 81%, 1);
}
html.dark .json-null {
  color: hsla(210, 74%, 81%, 1);
}

html.dark .json-key {
  color: hsla(333, 74%, 79%, 1);
}

/* START: react-select styles */
#workflow-select-input:focus {
  box-shadow: none;
}

.react-select__control {
  border-color: hsl(var(--input)) !important;
  background-color: transparent !important;
}

.react-select__control--is-focused {
  box-shadow: 0 0 0 1px hsl(var(--ring)) !important;
}

.react-select__input {
  font-size: 0.875rem !important;
  color: hsl(var(--foreground)) !important;
}

.react-select__input:focus {
  box-shadow: none;
}

.react-select__placeholder {
  color: hsl(var(--muted-foreground)) !important;
  font-size: 0.875rem !important;
}

.react-select__indicator-separator {
  background-color: hsl(var(--input)) !important;
}

.react-select__clear-indicator {
  color: hsl(var(--muted-foreground)) !important;
  cursor: pointer;
}

.react-select__dropdown-indicator {
  padding-left: 5px !important;
  padding-right: 5px !important;
  color: hsl(var(--muted-foreground)) !important;
}

.react-select__option {
  font-size: 0.875rem !important;
  padding: 6px 14px !important;
  cursor: pointer !important;
}

.react-select__option--is-selected {
  background-color: inherit !important;
  color: hsl(var(--foreground)) !important;
  border-radius: 4px;
  font-weight: 600;
}

.react-select__option--is-focused {
  background-color: hsl(var(--accent)) !important;
  padding: 6px 14px !important;
  border-radius: 4px;
}

.react-select__menu {
  background-color: hsl(var(--popover)) !important;
  border-width: 1px !important;
  border-color: hsl(var(--border)) !important;
  box-shadow: 0px 0px 4px 0px hsl(var(--border)) !important;
}

.react-select__multi-value {
  background-color: hsl(var(--accent)) !important;
  border-radius: 4px !important;
}

.react-select__multi-value__label {
  color: hsl(var(--foreground)) !important;
}

.react-select__multi-value__remove {
  color: hsl(var(--muted-foreground)) !important;
}

.react-select__multi-value__remove:hover {
  background-color: hsl(var(--accent)) !important;
}

.react-select__single-value {
  color: hsl(var(--foreground)) !important;
  font-size: 0.875rem !important;
}

.react-select__loading-indicator {
  color: hsl(var(--muted-foreground)) !important;
}

/* END: react-select styles */
