@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222 47% 11%;
    --card: 0 0% 100%;
    --card-foreground: 222 47% 11%;
    --popover: 0 0% 100%;
    --popover-foreground: 222 47% 11%;
    --primary: 218.71 80.17% 54.51%;
    --primary-foreground: 0 0% 100%;
    --primary-muted: 210 100% 96.86%;
    --primary-subtle: 214.48 88.29% 59.8%;
    --primary-tag: 210 100% 96.86%;
    --primary-text: 218.71 80.17% 54.51%;
    --secondary: 0 0% 100%;
    --secondary-foreground: 222 47% 11%;
    --muted: 210 40% 98%;
    --muted-foreground: 215.38 16.32% 46.86%;
    --accent: 210 40% 96%;
    --accent-foreground: 215.29 19.32% 34.51%;
    --destructive: 4.23 76.47% 40%;
    --destructive-foreground: 0 0% 100%;
    --destructive-subtle: 3.91 85.98% 58.04%;
    --destructive-muted: 5 85.71% 97.25%;
    --border: 214.3 31.8% 91.4%;
    --success: 155 96.77% 24.31%;
    --success-subtle: 152 82.09% 39.41%;
    --success-muted: 144.71 80.95% 95.88%;
    --success-tag: 144.71 80.95% 95.88%;
    --success-text: 155 96.77% 24.31%;
    --warning: 21.85 91.53% 37.06%;
    --warning-subtle: 34.03 93.7% 50.2%;
    --warning-muted: 45 100% 96.08%;
    --grey-tag: 210 40% 96%;
    --grey-text: 215.29 19.32% 34.51%;
    --input: 213 27% 84%;
    --ring: 219 80% 55%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 216 30% 13%;
    --foreground: 214.3 31.8% 91.4%;
    --muted: 217 29% 16%;
    --muted-foreground: 215 20% 65%;
    --popover: 216 30% 13%;
    --popover-foreground: 214.3 31.8% 91.4%;
    --card: 216 30% 13%;
    --card-foreground: 214.3 31.8% 91.4%;
    --border: 217.24 27.1% 20.98%;
    --input: 216 26% 24%;
    --primary: 214.48 88.29% 59.8%;
    --primary-subtle: 210.81 90.24% 67.84%;
    --primary-muted: 214.48 88.29% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --primary-tag: 214 88% 60% 0.15;
    --primary-text: 209.41 92.73% 78.43%;
    --secondary: 216 30% 13%;
    --secondary-foreground: 214.3 31.8% 91.4%;
    --accent: 216 26% 24%;
    --accent-foreground: 215 20% 65%;
    --success: 147.84 73.96% 66.86%;
    --success-subtle: 149.82 65.99% 51.57%;
    --success-muted: 152 82.09% 39.41%;
    --success-tag: 152 82% 39% 0.15;
    --success-text: 147.84 73.96% 66.86%;
    --warning: 41.9 98.9% 64.51%;
    --warning-subtle: 38.9 98.21% 56.27%;
    --warning-muted: 34.03 93.7% 50.2%;
    --destructive: 4.29 96.08% 80%;
    --destructive-subtle: 4.08 92.45% 68.82%;
    --destructive-foreground: 222.2 47.4% 11.2%;
    --destructive-muted: 3.91 85.98% 58.04%;
    --grey-tag: 215 20% 65% 0.15;
    --grey-text: 215 20% 65%;
    --ring: 214.48 88.29% 59.8%;
    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: 'rlig' 1, 'calt' 1;
  }
}
